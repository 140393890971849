import React from 'react'
import { Helmet } from 'react-helmet'
import '@/styles/scalehack/styleCo.scss'

import favicon from '@/images/mealforward/favicon.ico'
import appleIcon from '@/images/mealforward/apple-touch-icon.png'

type Props = {
  title: string
  url: string
}

export const MealForwardHead = (props: Props): JSX.Element => {
  const { title, url } = props

  return (
    <Helmet>
      <html lang="ja" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <title>{title}</title>
      <meta
        name="description"
        content="「日本の食品事業を前進させ、食の未来を創り出す」 「食を通じて、人々の健康・豊かさ・幸せを前進させる」をコンセプトに、フードテック/フードイノベーション事業を通じて食の変革を目指します。"
      />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`https://relic-holdings.co.jp/${url}`} />
      <meta
        property="og:image"
        content="https://relic-holdings.co.jp/OGP_1200*630_mealforward.png"
      />
      <meta
        property="og:description"
        content="「日本の食品事業を前進させ、食の未来を創り出す」 「食を通じて、人々の健康・豊かさ・幸せを前進させる」をコンセプトに、フードテック/フードイノベーション事業を通じて食の変革を目指します。"
      />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="icon" href={favicon} />
      <link
        rel="apple-touch-icon-precomposed"
        href={appleIcon}
        sizes="180x180"
      />
    </Helmet>
  )
}
