import { NewsType } from '@/typedir/types'

export const tempNewsList: NewsType[] = [
  {
    date: '2024.07.16',
    text: '海外初拠点、シンガポール現地法人「KEEP TECH JAPAN PTE. LTD.」設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000006.000129438.html'
  },
  {
    date: '2024.01.19',
    text: 'homeal株式会社と資本業務提携。子どもたちの食と、子育て家庭の食卓づくりのサポートを通して、多様化する個々の食生活に適したパーソナライズ化を発展。',
    url: 'https://prtimes.jp/main/html/rd/p/000000005.000129438.html'
  },
  {
    date: '2023.12.27',
    text: '食のECモール「3rd Menu」の事業を譲受、独自のECサイト「ENTER DINER」としてサービスを開始。食のエンターテイメントコンテンツの創造を目指す。',
    url: 'https://prtimes.jp/main/html/rd/p/000000004.000129438.html'
  },
  {
    date: '2023.11.15',
    text: '國酒プロモーション株式会社と資本業務提携。地方創生の拡大と、食のエンターテイメントコンテンツの創造を通した流通と購買のイノベーションを加速。',
    url: 'https://prtimes.jp/main/html/rd/p/000000003.000129438.html'
  },
  {
    date: '2023.10.31',
    text: 'クオリアース株式会社と資本業務提携。特殊凍結テクノロジーと地域産業・生産者との提携や、商品開発・流通の仕組みの拡大を加速。',
    url: 'https://prtimes.jp/main/html/rd/p/000000002.000129438.html'
  },
  {
    date: '2023.07.21',
    text: '食肉の流通と購買のイノベーションを実現する精肉EC事業「MEET✕MEAT」から、オリジナル企画の精肉及び肉惣菜の販売を開始。',
    url: 'https://prtimes.jp/main/html/rd/p/000000184.000016318.html'
  },
  {
    date: '2023.06.22',
    text: '夕刊フジにMEAL FORWARDを取材していただきました。',
    url: 'https://www.zakzak.co.jp/article/20230622-FFEHX66BBJLYJIHGQMKQO2VTH4/'
  },
  {
    date: '2022.06.07',
    text: 'Relicホールディングス、スタートアップスタジオ「ZERO1000 Ventures」の第2号案件として、食の流通と購買のイノベーションを実現する「株式会社MEAL FORWARD」を設立。',
    url: 'https://prtimes.jp/main/html/rd/p/000000170.000016318.html'
  }
]
